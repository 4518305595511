import {tns} from 'tiny-slider/src/tiny-slider';

let s,
  state,
  _this,
  App;

App = {
  settings: {
    els: {
      $menu: document.querySelector('[data-menu]'),
      $menuBtn: document.querySelector('[data-menu-btn]'),
      $gallery: document.querySelector('[data-gallery]'),
      $galleryDiy: document.querySelector('[data-diy-gallery]'),
      $galleryPrev: document.querySelector('[data-arrow-prev]'),
      $galleryNext: document.querySelector('[data-arrow-next]')
    },
    menuIsOpened: false,
  },

  init: function init() {
    s = this.settings;
    _this = this;
    this.events.init();
    this.gallery.init();
  },

  helpers: {
    addClass: function addClass(element, className) {
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className += ' ' + className;
      }
    },

    removeClass: function removeClass(element, className) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    }
  },

  gallery: {
    init: function init() {
      if (s.els.$gallery) {
        const slider = tns({
          container: '[data-gallery]',
          items: 1,
          navContainer: '[data-gallery-thumbs]',
          controlsContainer: '[data-gallery-next]',
          navAsThumbnails: true
        });
      }

      if (s.els.$galleryDiy) {
        const diySlider = tns({
          container: '[data-diy-gallery]',
          items: 1,
          navContainer: '[data-diy-gallery-thumbs]',
          controls: false,
          navAsThumbnails: true,
          responsive: {
            '769': {
              disable: true
            }
          }
        });
      }
    }
  },

  events: {
    init: function init() {
      this.toggleMenu();
    },

    toggleMenu: function toggleMenu() {
      s.els.$menuBtn.addEventListener('click', function () {
        if (s.menuIsOpened) {
          _this.helpers.removeClass(s.els.$menu, '--opened')
          _this.helpers.removeClass(s.els.$menuBtn, '--opened')
          s.menuIsOpened = false
        } else {
          _this.helpers.addClass(s.els.$menu, '--opened')
          _this.helpers.addClass(s.els.$menuBtn, '--opened')
          s.menuIsOpened = true
        }
      });
    }
  }
};

App.init();
